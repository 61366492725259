import { observable, action } from 'mobx';
import Service from 'services';

class ProfileStore {
  @observable loading = false;
  @observable base = {};
  @observable auth = {};
  @observable directions = [];
  @observable educationList = [];
  @observable positionList = [];
  @observable domainList = [];
  @observable cards = [];
  @observable frames = [];
  @action
  async queryBase() {
    this.loading = true;
    return await Service.Profile.getBase()
      .then((result) => {
        this.base = result;
        return Promise.resolve(result);
      })
      .catch((e) => {
        return Promise.reject(e);
      })
      .finally(() => (this.loading = false));
  }
  @action
  async getDirections() {
    this.loading = true;
    return await Service.Profile.getDirections()
      .then((result) => {
        this.directions = result.list;
        return Promise.resolve(result);
      })
      .catch((e) => {
        return Promise.reject(e);
      })
      .finally(() => (this.loading = false));
  }
  @action
  async saveBase(base: any) {
    this.loading = true;
    return await Service.Profile.saveBase(base)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((e) => {
        return Promise.reject(e);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  @action
  async queryAuth() {
    this.loading = true;
    return await Service.Profile.getAuth()
      .then((result) => {
        this.auth = result;
        return Promise.resolve(result);
      })
      .catch((e) => {
        return Promise.reject(e);
      })
      .finally(() => (this.loading = false));
  }
  @action
  async getCards() {
    return await Service.Profile.getCardList()
      .then((result) => {
        this.cards = result.list;
        return Promise.resolve(result);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  }
  @action
  async getEducationList() {
    return await Service.Profile.getEducationList()
      .then((result) => {
        this.educationList = result.list;
        return Promise.resolve(result);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  }
  @action
  async getDomainList() {
    return await Service.Profile.getDomainList()
      .then((result) => {
        this.domainList = result.list;
        return Promise.resolve(result);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  }
  @action
  async getPositionList() {
    return await Service.Profile.getPositionList()
      .then((result) => {
        this.positionList = result.list;
        return Promise.resolve(result);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  }
  @action
  async getFrames() {
    return await Service.Instance.frame()
      .then((result) => {
        this.frames = result.list;
        return Promise.resolve(result);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  }
}
export default new ProfileStore();
