export function getToken() {
  let tokenObj;
  try {
    let tokenObjStr = window.localStorage.getItem('token');
    tokenObj = tokenObjStr ? JSON.parse(tokenObjStr) : {};
  } catch {
    console.error('get token from localStorage error');
  }
  return tokenObj;
}
export function setToken(tokenObj: any) {
  removeToken();
  window.localStorage.setItem('token', tokenObj);
}
export function removeToken() {
  window.localStorage.removeItem('token');
}
