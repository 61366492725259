import request from 'utils/request';
interface QueryParams {
  id: number | string;
  current?: number;
  pageSize?: number;
}
export default {
  list: (params: QueryParams): Promise<any> =>
    request({
      url: `/online-train/instance/${params.id}/train/paginate`,
      method: 'get',
      withCredentials: true,
      params: {
        current: params.current,
        pageSize: params.pageSize,
      },
    }),
  modelList: (id: number | string): Promise<any> =>
    request({
      url: `/online-train/instance/train/${id}/model-dir`,
      method: 'get',
      withCredentials: true,
    }),
  create: (id: string | number | undefined, data: any): Promise<any> =>
    request({
      url: `/online-train/instance/${id}/train`,
      method: 'post',
      withCredentials: true,
      data,
    }),
  detail: (trainId: number | string): Promise<any> =>
    request({
      url: `/online-train/instance/train/${trainId}`,
      method: 'get',
      withCredentials: true,
    }),
  revoked: (trainId: number): Promise<any> =>
    request({
      url: `/online-train/instance/train/${trainId}/revoked`,
      method: 'patch',
      withCredentials: true,
    }),
  aborted: (trainId: number): Promise<any> =>
    request({
      url: `/online-train/instance/train/${trainId}/aborted`,
      method: 'patch',
      withCredentials: true,
    }),
  getTerminalLog: (trainId: number | string, first?: boolean): Promise<any> =>
    request({
      url: `/online-train/instance/train/${trainId}/real-time-log/${first}`,
      method: 'get',
      withCredentials: true,
    }),
};
