/*
 * @Descripttion:
 * @version:
 * @Author: Jianyong Wang
 * @Date: 2020-04-24 09:31:11
 * @LastEditors: Jianyong Wang
 * @LastEditTime: 2020-04-24 11:51:16
 */
import Project from './project';
import Instance from './instance';
import App from './app';
import Profile from './profile';
import Train from './train';
import ForgetPassword from './forgetPassword';
import SDK from './SDK';
import Stream from './stream';

const Service = {
  App,
  Project,
  Instance,
  Profile,
  Train,
  ForgetPassword,
  SDK,
  Stream
};

export default Service;
