import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { notification } from 'antd';
import { getToken, setToken } from './auth';
import { debug } from 'console';

// 定义http请求提示
const codeMessage: { [key: string]: any } = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误），即将跳转到登录页面。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};
/**
 * 异常处理程序
 */
const errorHandler = (error: { response: AxiosResponse }) => {
  const { response } = error;
  const { status = '' } = response;
  if (status === 401) {
    console.log('status 401', status)
    // debugger
    window.location.href = '/login';
  }
  const errorText = response.data.msg || codeMessage[response.status] || response.statusText;
  notification.error({
    message: `请求错误 ${status}`,
    description: errorText,
  });
  if (!response) {
    notification.error({
      description: '您的网络发生异常，无法连接服务器',
      message: '网络异常',
    });
  }
  return Promise.reject(response);
};
const request = Axios.create({
  baseURL: '/api',
});
/**
 * 刷新token
 */
function refreshToken() {
  console.log('refresh func···');
  const tokenObj = getToken() as any;
  return Axios({
    url: '/api/refresh',
    method: 'put',
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${tokenObj.token}`,
    },
  }).then((response) => response.data);
}
// let isRefreshing = false;
// let retryRequests: any[] = [];
request.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (
      config.url?.indexOf &&
      (config.url?.indexOf('/refresh') >= 0 || config.url?.indexOf('/login') >= 0)
    ) {
      return config;
    }
    if (config.withCredentials) {
      const tokenObj = getToken() as any;
      // // 判断token是否存在
      // if (tokenObj.token && tokenObj.tokenExpireTime) {
      //   const now = Date.now();
      //   // 判断是否过期
      //   if (now >= tokenObj.tokenExpireTime) {
      //     if (!isRefreshing) {
      //       console.log('refreshing');
      //       isRefreshing = true;
      //       refreshToken()
      //         .then((result: any) => {
      //           console.log('000', result);
      //           const { access_token, expires_in } = result;
      //           const tokenExpireTime = now + expires_in * 1000;
      //           // const tokenExpireTime = now + 15 * 1000;
      //           console.log('tokenExpireTime', new Date(tokenExpireTime))
      //           setToken(JSON.stringify({ token: access_token, tokenExpireTime }));
      //           config.headers['Authorization'] = `Bearer ${access_token}`;
      //           return access_token;
      //         })
      //         .then((token: string) => {
      //           retryRequests.forEach((cb) => cb(token));
      //           retryRequests = [];
      //         })
      //         .catch((error: any) => {
      //           console.error('refresh token error.', error);
      //           window.location.href = '/login';
      //         })
      //         .finally(() => {
      //           isRefreshing = false;
      //         });
      //     }
      //     return new Promise((resolve) => {
      //       retryRequests.push((token: string) => {
      //         // 因为config中的token是旧的，所以刷新token后要将新token传进来
      //         config.headers['Authorization'] = `Bearer ${token}`;
      //         resolve(config);
      //       });
      //     });
      //   } else {
      //     // 没有过期正常返回认证头部
      //     config.headers['Authorization'] = `Bearer ${tokenObj.token}888`;
      //   }


      // }
      config.headers['Authorization'] = `Bearer ${tokenObj.token}`;
    }
    return config;
  },
  (error: any) => {
    // do something width error
    return Promise.reject(error);
  },
);
request.interceptors.response.use(
  (response: AxiosResponse) => {
    const result = response.data;

    if (result.code !== 20000) {
      return Promise.reject(result);
    }
    return Promise.resolve(result);
  },
  (error) => errorHandler(error),
);
export default request;
