/*
 * @Descripttion: 
 * @version: 
 * @Author: Jianyong Wang
 * @Date: 2020-04-24 11:48:46
 * @LastEditors: Jianyong Wang
 * @LastEditTime: 2020-04-24 12:13:02
 */
import request from 'utils/request';

export default {
  //  获取验证码
  resetPasswordSms: (params: any): Promise<any> =>
    request({
      url: '/reset-password-sms',
      method: 'get',
      params
    }),
  //  重置密码提交 
  resetPasswordSubmit: (data: any): Promise<any> =>
    request({
      url: '/reset-password',
      method: 'post',
      data
    }),
};
