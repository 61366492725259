import request from 'utils/request';
interface QueryParams {
  id: number | string;
  current?: number;
  pageSize?: number;
}
export default {
  getList: (params: any): Promise<any> =>
    request({
      url: `/online-train/instance/flow/item`,
      method: 'get',
      withCredentials: true,
      params
    }),
};
