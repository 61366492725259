/*
 * @Descripttion:
 * @version:
 * @Author: Jianyong Wang
 * @Date: 2020-11-30 14:53:47
 * @LastEditors: Jianyong Wang
 * @LastEditTime: 2020-12-10 10:34:56
 */
import { observable, action } from 'mobx';
import { getToken, setToken, removeToken } from 'utils/auth';
import { setStorageItem, getStorageItem, clearStorage } from 'utils/util'
import Service from 'services';

class UserStore {
  constructor() {
    this.init();
  }

  @observable loading = false;
  @observable profile = {};
  @observable user = getStorageItem('userInfo') || {};

  init() {
    const tokenObj = getToken();
    this.profile = tokenObj;
  }
  @action
  login(values: { [key: string]: any }) {
    this.loading = true;
    return Service.App.login(values)
      .then((result) => {
        const now = Date.now();
        const { token_info, user_info } = result;
        let tokenObj = {
          token: token_info.access_token,
          tokenExpireTime: now + Number(token_info.expires_in) * 1000,
          userId: user_info.id,
        };

        setStorageItem('token', tokenObj)

        this.init();

        return 1;
      })
      .catch((e) => {
        console.log(e)
        return {type: 'error', msg: e.msg || '请求异常'};
      })
      .finally(() => (this.loading = false));
  }
  @action
  logout() {
    this.loading = true;
    return Service.App.logout()
      .then((result) => {
        clearStorage()

        return result;
      })
      .catch((e) => {
        return Promise.reject(e);
      })
      .finally(() => (this.loading = false));
  }
  @action
  register(values: { [key: string]: any }) {
    this.loading = true;
    return Service.Profile.register(values)
      .then((result) => {
        removeToken();
        const now = Date.now();
        const { token_info, user_info } = result;
        let tokenObj = {
          token: token_info.access_token,
          tokenExpireTime: now + Number(token_info.expires_in) * 1000,
          userId: user_info.id,
        };

        setStorageItem('token', tokenObj)

        this.init();
        return Promise.resolve();
      })
      .catch((e) => {
        return Promise.reject(e);
      })
      .finally(() => (this.loading = false));
  }
  @action
  queryUser() {
    return Service.App.me()
      .then((res) => {
        this.user = res;

        setStorageItem('userInfo', res)

        return res;
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  }
}
export default new UserStore();
