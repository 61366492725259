import request from 'utils/request';

interface QueryParams {}
interface IData {}
export default {
  refresh: (): Promise<any> =>
    request({
      url: '/refresh',
      method: 'put',
      withCredentials: true,
    }),
  getVerifyRegister: (params: QueryParams): Promise<any> =>
    request({
      url: '/register-sms',
      method: 'get',
      params,
    }),
  getImgCode: (): Promise<any> =>
    request({
      url: '/register-captcha',
      method: 'get',
    }),
  register: (data: IData): Promise<any> =>
    request({
      url: '/register',
      method: 'post',
      data,
    }),
  getBase: (): Promise<any> =>
    request({
      url: '/user/base',
      method: 'get',
      withCredentials: true,
    }),
  getIntegral: (params: any): Promise<any> =>
    request({
      url: '/user/level-credit/integral',
      method: 'get',
      withCredentials: true,
      params,
    }),
  getDirections: (): Promise<any> =>
    request({
      url: '/user/base/research-direction',
      method: 'get',
      withCredentials: true,
    }),
  saveBase: (data: IData): Promise<any> =>
    request({
      url: '/user/base',
      method: 'put',
      withCredentials: true,
      data,
    }),
  getVerifyBindEmail: (params: QueryParams): Promise<any> =>
    request({
      url: '/user/base/bind-email/verification-code',
      method: 'get',
      withCredentials: true,
      params,
    }),
  saveEmailBind: (data: IData): Promise<any> =>
    request({
      url: '/user/base/bind-email',
      method: 'post',
      withCredentials: true,
      data,
    }),
  getVerifyEmailOld: (): Promise<any> =>
    request({
      url: '/user/base/update-email/old-email-verification-code',
      method: 'get',
      withCredentials: true,
    }),
  getVerifyEmailNew: (params: QueryParams): Promise<any> =>
    request({
      url: '/user/base/update-email/new-email-verification-code',
      method: 'get',
      withCredentials: true,
      params,
    }),
  saveEmailBindChange: (data: IData): Promise<any> =>
    request({
      url: '/user/base/update-email',
      method: 'patch',
      withCredentials: true,
      data,
    }),
  getVerifyEmailBindMobile: (): Promise<any> =>
    request({
      url: '/user/base/update-mobile/old-email-verification-code',
      method: 'get',
      withCredentials: true,
    }),
  getVerifyMobileBindMobile: (): Promise<any> =>
    request({
      url: '/user/base/update-mobile/old-mobile-verification-code',
      method: 'get',
      withCredentials: true,
    }),
  getVerifyMobileBindNewMobile: (params: QueryParams): Promise<any> =>
    request({
      url: '/user/base/update-mobile/new-mobile-verification-code',
      method: 'get',
      withCredentials: true,
      params,
    }),
  saveMobileBindChange: (data: IData): Promise<any> =>
    request({
      url: '/user/base/update-mobile',
      method: 'patch',
      withCredentials: true,
      data,
    }),
  changePwd: (data: IData): Promise<any> =>
    request({
      url: '/user/base/update-password',
      method: 'patch',
      withCredentials: true,
      data,
    }),
  getAuth: (): Promise<any> =>
    request({
      url: '/user/authenticate',
      method: 'get',
      withCredentials: true,
    }),
  getCardList: (): Promise<any> =>
    request({
      url: '/user/authenticate/certificate-type',
      method: 'get',
      withCredentials: true,
    }),
  saveReal: (data: IData): Promise<any> =>
    request({
      url: '/user/authenticate/real-name',
      method: 'put',
      withCredentials: true,
      data,
    }),
  getEducationList: (): Promise<any> =>
    request({
      url: '/user/authenticate/education',
      method: 'get',
      withCredentials: true,
    }),
  saveEduc: (data: IData): Promise<any> =>
    request({
      url: '/user/authenticate/educational-info',
      method: 'put',
      withCredentials: true,
      data,
    }),
  getPositionList: (): Promise<any> =>
    request({
      url: '/user/authenticate/position',
      method: 'get',
      withCredentials: true,
    }),
  getDomainList: (): Promise<any> =>
    request({
      url: '/user/authenticate/domain',
      method: 'get',
      withCredentials: true,
    }),
  saveJob: (data: IData): Promise<any> =>
    request({
      url: '/user/authenticate/job-info',
      method: 'put',
      withCredentials: true,
      data,
    }),
  getAwardLevel: (): Promise<any> =>
    request({
      url: '/user/authenticate/award-level',
      method: 'get',
      withCredentials: true,
    }),
  saveAward: (data: IData): Promise<any> =>
    request({
      url: '/user/authenticate/award-info',
      method: 'post',
      withCredentials: true,
      data,
    }),
  getLevel: (): Promise<any> =>
    request({
      url: '/user/level-credit',
      method: 'get',
      withCredentials: true,
    }),
  getProject: (params: {}): Promise<any> =>
    request({
      url: '/user/participate-project/paginate',
      method: 'get',
      withCredentials: true,
      params,
    }),
  countInfo: (params: {}): Promise<any> =>
    request({
      url: '/user/participate-project/count-info',
      method: 'get',
      withCredentials: true,
      params,
    }),
  getFile: (params: {}): Promise<any> =>
    request({
      url: '/file/paginate',
      method: 'get',
      withCredentials: true,
      params,
    }),
  countFile: (): Promise<any> =>
    request({
      url: '/file/count-info',
      method: 'get',
      withCredentials: true,
    }),
  delFile: (id: string | number): Promise<any> =>
    request({
      url: `/file/${id}`,
      method: 'delete',
      withCredentials: true,
    }),
  uploadFile: (data: any): Promise<any> =>
    request({
      url: '/file/max-file/upload',
      method: 'post',
      withCredentials: true,
      data,
    }),
  getProjectCollection: (): Promise<any> =>
    request({
      url: '/project',
      method: 'get',
      withCredentials: true,
    }),
  applyIntegral: (project_id: string | number, data: any): Promise<any> =>
    request({
      url: `/user/level-credit/${project_id}/apply-integral`,
      method: 'post',
      withCredentials: true,
      data,
    }),
  getParticipateProject: (): Promise<any> =>
    request({
      url: '/user/level-credit/participate-project',
      method: 'get',
      withCredentials: true,
    }),
  intrgralTo: (data: any): Promise<any> =>
    request({
      url: '/user/level-credit/integral/exchange-general-to-dedicated',
      method: 'post',
      withCredentials: true,
      data,
    }),
  getAlgos: (params: QueryParams): Promise<any> =>
    request({
      url: '/user/base/algo-list',
      method: 'get',
      withCredentials: true,
      params,
    }),
  getLicenseList: (params: any): Promise<any> =>
    request({
      url: '/user/base/license-list',
      method: 'get',
      withCredentials: true,
      params,
    }),
  getLicenseInfo: (params: any): Promise<any> =>
    request({
      url: '/user/base/license-info',
      method: 'get',
      withCredentials: true,
      params,
    }),
};
