
//  读取localStorage
const getStorageItem = (key) => {
  let val:any = window.localStorage.getItem(key)
  return JSON.parse(val)
}

//  读取localStorage
const setStorageItem = (key, val) => {
  window.localStorage.setItem(key, JSON.stringify(val))
}

//  删除
const removeStoreItem = (key) => {
  window.localStorage.removeItem(key)
}

const clearStorage = () => {
  window.localStorage.clear()
}

//  禁止中文输入
const stopChinese = (val) => {
  return val.replace(/[\u4E00-\u9FA5]/gi, '')
}

//  列表分页 查询没有数据自动查询上一页  （非第一页）
const tableListIsCheckPrePage = (list, currentPage) => {
  if (currentPage === 1) return false

  if (!list.length) {
    return currentPage - 1
  }
}

//  事件绑定兼容
const addHandle = (dom, type, fn) => {
  // @ts-ignore
  if (document.addEventListener) {
    dom.addEventListener(type, fn, false)
  } else if ((document as any).attachEvent) {
    dom.attachEvent('on' + type, fn)
  } else {
    let oldDomFun = dom['on' + type]
    dom['on' + type] = function() {
      oldDomFun()
      fn && fn()
    }
  }
}

//  事件解绑
const removeHandle = (dom, type, fn) => {
  // @ts-ignore
  if (document.removeEventListener) {
    dom.removeEventListener(type, fn)
  } else if ((document as any).detachEvent) {
    dom.detachEvent('on' + type, fn)
  } else {
    dom['on' + type] = null
  }
}

//  判断当前环境
const isDev = () => {
  return process.env.NODE_ENV === 'production' ? false : true
}

const getImgSrc = (url) => {
  if (isDev()) {
    url = url ? ('http://ji_algo_config.com/' + url) : url
    return url
  }

  if (url) {
    let reg = /(http)|(https):\/\//g

    if (reg.test(url)) {
      return url
    }
    return '/' + url
  }

  return url
}

//  中英文获取语言 对应key的val key支持对象obj.info.name链式取值
const getLanguageVal = (store, key) => {
  let keys = key.split('.')
  let obj = JSON.parse(store.getState())
  let len = keys.length

  for (let i = 0; i < len; i++) {
    let item = keys[i]
    obj = obj[item]
  }
  return obj
}

//  异步执行函数 测试数据
const asyncTestData = (delay, data) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data)
    }, delay)
  })
}

//  生成UID
let index = 0;
const getUid = () => {
  const now = +(new Date());

  return `rc-upload-${now}-${++index}`
}

//  退出浏览器全屏


export {
  getStorageItem,
  setStorageItem,
  removeStoreItem,
  clearStorage,
  stopChinese,
  tableListIsCheckPrePage,
  addHandle,
  removeHandle,
  isDev,
  getImgSrc,
  getLanguageVal,
}
