import React, { Suspense } from 'react';
import { Spin,ConfigProvider } from 'antd';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import zhCN from 'antd/es/locale/zh_CN'; // 国际化调整中文
import 'moment/locale/zh-cn'; // 日期控件中文
// import moment from 'moment';
// moment.locale('zh');
const Login = React.lazy(() => import('./pages/login'));
const Home = React.lazy(() => import('./pages/home'));
const Project = React.lazy(() => import('./pages/project'));
const Race = React.lazy(() => import('./pages/race'));
const ProjectDetail = React.lazy(() => import('./pages/project/detail'));
const RaceDetail = React.lazy(() => import('./pages/race/detail'));
const Register = React.lazy(() => import('./pages/register'));
const ForgetPassword = React.lazy(() => import('./pages/forgetPassword'));
const Instance = React.lazy(() => import('./pages/instance'));
const InstanceDetail = React.lazy(() => import('./pages/instance/detail'));
const Profile = React.lazy(() => import('./pages/profile'));
const Document = React.lazy(() => import('./pages/document'));
const Code = React.lazy(() => import('./pages/code'));
// const Cv2018 = React.lazy(() => import('./pages/cv101/cv2018'));
// const Cv2019 = React.lazy(() => import('./pages/cv101/cv2019'));
// const Cv2020 = React.lazy(() => import('./pages/cv101/cv2020'));
// const Award2019 = React.lazy(() => import('./pages/cv101/award2019'));
const Error404 = React.lazy(() => import('./404'));

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <Suspense fallback={<Spin className="appSpin" spinning={true} tip="正在加载中······" />}>
        <Router>
          <Switch>
            {/* 首页 */}
            <Route exact path="/">
              <Home />
            </Route>
            {/* 登录 */}
            <Route path="/login">
              <Login />
            </Route>
            {/* 注册 */}
            <Route path="/register">
              <Register />
            </Route>
            {/* 忘记密码 */}
            <Route path="/forgetpassword">
              <ForgetPassword />
            </Route>
            {/* 项目详情 */}
            <Route path="/project/:raceid">
              <ProjectDetail />
            </Route>
            {/* 项目 */}
            <Route path="/project">
              <Project />
            </Route>
            {/* 竞赛详情 */}
            <Route path="/race/:raceid">
              <RaceDetail />
            </Route>
            {/* 竞赛 */}
            <Route path="/race">
              <Race />
            </Route>
            {/* 实例详情 */}
            <Route path="/instance/:instanceid">
              <InstanceDetail />
            </Route>
            {/* 开发环境 */}
            <Route path="/instance">
              <Instance />
            </Route>
            {/* 个人中心 */}
            <Route path="/profile">
              <Profile />
            </Route>
            {/* 文档中心 */}
            <Route path="/document/:path">
              <Document />
            </Route>
            {/* 文档中心 */}
            <Route path="/document">
              <Document />
            </Route>
            {/* 编码面板 */}
            <Route path="/code/:instanceid">
              <Code />
            </Route>
            <Route path="/code">
              <Code />
            </Route>
            {/*<Route path="/list/ECV2018">*/}
            {/*  <Cv2018 />*/}
            {/*</Route>*/}
            {/*<Route path="/list/ECV2019">*/}
            {/*  <Cv2019 />*/}
            {/*</Route>*/}
            {/*<Route path="/list/ECV2020">*/}
            {/*  <Cv2020 />*/}
            {/*</Route>*/}
            {/*<Route path="/award2019">*/}
            {/*  <Award2019 />*/}
            {/*</Route>*/}
            <Route path="*">
              <Error404 />
            </Route>
          </Switch>
        </Router>
      </Suspense>
    </ConfigProvider>
  );
}

export default App;
