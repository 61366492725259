import request from 'utils/request';
import { getToken } from 'utils/auth';
const hasToken = getToken();
interface QueryParams {}
// interface IData {}
export default {
  list: (params: QueryParams): Promise<any> =>
    request({
      url: '/project/paginate',
      method: 'get',
      withCredentials: !!hasToken,
      params,
    }),
  common: (id: number | string): Promise<any> =>
    request({
      url: `/project/${id}`,
      method: 'get',
      withCredentials: !!hasToken,
    }),
  base: (id: number | string): Promise<any> =>
    request({
      url: `/project/base/${id}`,
      method: 'get',
      withCredentials: !!hasToken,
    }),
  des: (id: number): Promise<any> =>
    request({
      url: `/project/description/${id}`,
      method: 'get',
      withCredentials: !!hasToken,
    }),
  dataset: (id: string | number): Promise<any> =>
    request({
      url: `/project/data-set/${id}`,
      method: 'get',
      withCredentials: !!hasToken,
    }),
  datasetImages: (project_id: string | number, data_set_id: string | number): Promise<any> =>
    request({
      url: `/project/data-set/${project_id}/sample-picture/${data_set_id}`,
      method: 'get',
      withCredentials: !!hasToken,
    }),
  register: (project_id: string | number, data?: any): Promise<any> =>
    request({
      url: `/project/register/${project_id}`,
      method: 'post',
      withCredentials: !!hasToken,
      data,
    }),
  rank: (params: any): Promise<any> =>
    request({
      url: `/project/leader-board/${params.id}/paginate`,
      method: 'get',
      withCredentials: !!hasToken,
      params: {
        current: params.current,
        pageSize: params.pageSize,
      },
    }),
  evaluation: (project_id: number | string): Promise<any> =>
    request({
      url: `/project/evaluation-target/${project_id}`,
      method: 'get',
      withCredentials: !!hasToken,
    }),
  getSignupCode: (params: any): Promise<any> =>
    request({
      url: '/project/subscribe/email-code',
      method: 'get',
      withCredentials: true,
      params,
    }),
  getSchools: (): Promise<any> =>
    request({
      url: '/project/pre-register/university',
      method: 'get',
      withCredentials: !!hasToken,
    }),

  getSignInfo: (raceid: number | string): Promise<any> =>
    request({
      url: `/project/pre-register/user/${raceid}`,
      method: 'get',
      withCredentials: true,
    }),
  postPv: (): Promise<any> =>
    request({
      url: '/contest/pv-page',
      method: 'post',
      withCredentials: !!hasToken,
      data: {
        source: 'pc',
        type: 0,
      },
    }),
};
