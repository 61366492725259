import request from 'utils/request';
export default {
  login: (data: any): Promise<any> =>
    request({
      url: '/login',
      method: 'post',
      data,
    }),
  logout: (): Promise<any> =>
    request({
      url: '/logout',
      method: 'delete',
      withCredentials: true,
    }),
  me: (): Promise<any> =>
    request({
      url: '/me',
      method: 'get',
      withCredentials: true,
    }),
  refresh: (): Promise<any> =>
    request({
      url: '/refresh',
      method: 'put',
      withCredentials: true,
    }),
};
