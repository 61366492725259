import request from 'utils/request';
interface QueryParams {
  id: number | string;
  current?: number;
  pageSize?: number;
}
export default {
  curSDK: (id: string | number | undefined): Promise<any> =>
    request({
      url: `/online-train/instance/algo/${id}/sdk-package/base-info/4`,
      method: 'get',
      withCredentials: true
    }),
  sdkList: (params: any): Promise<any> =>
    request({
      url: `/online-train/instance/algo/${params.id}/sdk-package/paginate`,
      method: 'get',
      withCredentials: true,
      params: {
        current: params.current,
        pageSize: params.pageSize,
      },
    }),
  postSDK: (id: string | number | undefined, data: any): Promise<any> =>
    request({
      url: `/online-train/instance/algo/${id}/sdk-package`,
      method: 'post',
      withCredentials: true,
      data,
    }),
};
