import request from 'utils/request';
interface QueryParams {}
export default {
  list: (params: QueryParams): Promise<any> =>
    request({
      url: '/online-train/instance/paginate',
      method: 'get',
      withCredentials: true,
      params,
    }),
  common: (params: any): Promise<any> =>
    request({
      url: `/online-train/instance/${params.instanceid}`,
      method: 'get',
      withCredentials: true,
    }),
  frame: (): Promise<any> =>
    request({
      url: `/online-train/instance/frame`,
      method: 'get',
      withCredentials: true,
    }),
  create: (data: any): Promise<any> =>
    request({
      url: `/online-train/instance`,
      method: 'post',
      withCredentials: true,
      data,
    }),
  switchFrame: (id: number | string, data: any): Promise<any> =>
    request({
      url: `/online-train/instance/${id}/switch-frame`,
      method: 'patch',
      withCredentials: true,
      data,
    }),
  base: (id: number | string): Promise<any> =>
    request({
      url: `/online-train/instance/base/${id}`,
      method: 'get',
      withCredentials: true,
    }),
  stop: (id: number | string): Promise<any> =>
    request({
      url: `/online-train/instance/${id}/stop`,
      method: 'patch',
      withCredentials: true,
    }),
  start: (id: number | string): Promise<any> =>
    request({
      url: `/online-train/instance/start/${id}`,
      method: 'patch',
      withCredentials: true,
    }),
  restore: (id: number | string, type: string, mirror_id: number | string): Promise<any> =>
    request({
      url: `/online-train/instance/${type}/${id}/${mirror_id}/restore`,
      method: 'patch',
      withCredentials: true,
    }),
  buildImage: (id: number | string, type: string): Promise<any> =>
    request({
      url: `/online-coding/instance/${id}/generate-${type}-mirror`,
      method: 'post',
      withCredentials: true,
    }),
  rebuild: (id: number): Promise<any> =>
    request({
      url: `/online-train/instance/${id}/rebuild`,
      method: 'patch',
      withCredentials: true,
    }),
  checkDockfile: (id: number, type: string): Promise<any> =>
    request({
      url: `/online-coding/instance/${id}/check-dockerfile/${type}`,
      method: 'get',
      withCredentials: true,
    }),
  checkDockerFileLog: (id: number): Promise<any> =>
    request({
      url: `/online-coding/instance/${id}/check-build-mirror-status`,
      method: 'get',
      withCredentials: true,
    }),
  uploadModel: (id: number, data: any): Promise<any> =>
    request({
      url: `/online-coding/instance/${id}/model-upload`,
      method: 'post',
      withCredentials: true,
      data,
    }),
  uploadDataset: (id: number, data: any): Promise<any> =>
    request({
      url: `/online-coding/instance/${id}/data-set-upload`,
      method: 'post',
      withCredentials: true,
      data,
    }),
  uploadDep: (id: number, data: any): Promise<any> =>
    request({
      url: `/online-coding/instance/${id}/rely-upload`,
      method: 'post',
      withCredentials: true,
      data,
    }),
  models: (params: any): Promise<any> =>
    request({
      url: `/online-train/instance/${params.instanceid}/model/paginate`,
      method: 'get',
      withCredentials: true,
      params,
    }),
  tests: (params: any): Promise<any> =>
    request({
      url: `/online-train/instance/${params.id}/sdk-test/paginate`,
      method: 'get',
      withCredentials: true,
      params,
    }),
  algos: (params: any): Promise<any> =>
    request({
      url: `/online-train/instance/${params.id}/algo/paginate`,
      method: 'get',
      withCredentials: true,
      params,
    }),
  algoSubmit: (id: number): Promise<any> =>
    request({
      url: `/online-train/instance/algo/${id}/submit`,
      method: 'post',
      withCredentials: true,
    }),
  getModelFiles: (id: number | string): Promise<any> =>
    request({
      url: `/online-train/instance/sdk-test/${id}/model-dir`,
      method: 'get',
      withCredentials: true,
    }),
  getTreeModelFiles: (id: number | string): Promise<any> =>
    request({
      url: `/online-train/instance/${id}/sdk-test-dir-list`,
      method: 'get',
      withCredentials: true,
    }),
  testCreate: (id: number | string, data: any): Promise<any> =>
    request({
      url: `/online-train/instance/${id}/sdk-test`,
      method: 'post',
      withCredentials: true,
      data,
    }),
  testCreateOpenvino: (id: number | string, data: any): Promise<any> =>
    request({
      url: `/online-train/instance/${id}/sdk-test-open-vino`,
      method: 'post',
      withCredentials: true,
      data,
    }),
  testRevoked: (id: number): Promise<any> =>
    request({
      url: `/online-train/instance/sdk-test/${id}/revoked`,
      method: 'patch',
      withCredentials: true,
    }),
  testAborted: (id: number): Promise<any> =>
    request({
      url: `/online-train/instance/sdk-test/${id}/aborted`,
      method: 'patch',
      withCredentials: true,
    }),
  enterRank: (id: number): Promise<any> =>
    request({
      url: `/online-train/instance/sdk-test/enter-leader-board/${id}`,
      method: 'post',
      withCredentials: true,
    }),
  testInfo: (id: number | string): Promise<any> =>
    request({
      url: `/online-train/instance/sdk-test/${id}`,
      method: 'get',
      withCredentials: true,
    }),
  getCodeIntergal: (id: number | string): Promise<any> =>
    request({
      url: `/online-coding/instance/${id}/project-integrals-consume`,
      method: 'get',
      withCredentials: true,
    }),
  getDashBoard: (id: number | string): Promise<any> =>
    request({
      url: `/online-coding/instance/dashboard/${id}`,
      method: 'get',
      withCredentials: true,
    }),
  getMirror: (type: string, id: number | string): Promise<any> =>
    request({
      url: `/online-coding/instance/${type}/${id}/mirror`,
      method: 'get',
      withCredentials: true,
    }),
  getBuildLog: (type: string, id: number | string, first?: boolean): Promise<any> =>
    request({
      url: `/online-coding/instance/${id}/generate-${type}-mirror/real-time-log/${first}`,
      method: 'get',
      withCredentials: true,
    }),
  getPreModel: (params: any): Promise<any> =>
    request({
      url: '/online-coding/instance/system-pre-model/paginate',
      method: 'get',
      withCredentials: true,
      params,
    }),
  getTrainPlot: (id: number | string): Promise<any> =>
    request({
      url: `/online-train/instance/train/${id}/plot`,
      method: 'get',
      withCredentials: true,
    }),
  queryModelMem: (id: number | string): Promise<any> =>
    request({
      url: `/online-train/instance/${id}/model/count-info`,
      method: 'get',
      withCredentials: true,
    }),
  queryModelByTrainId: (params: any): Promise<any> =>
    request({
      url: `/online-train/instance/${params.id}/model-dir/paginate`,
      method: 'get',
      withCredentials: true,
      params,
    }),
  modelDel: (id: any): Promise<any> =>
    request({
      url: `/online-train/instance/model-dir/${id}`,
      method: 'delete',
      withCredentials: true,
    }),
  querySource: (type: string, id: number | string): Promise<any> =>
    request({
      url: `/online-coding/instance/resource/${id}/${type}`,
      method: 'get',
      withCredentials: true,
    }),
};
